import { useState, useEffect } from 'react';
import { auth } from './firebase';
import { signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { db } from './firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';

import Dashboard from './Dashboard';

import logo from './assets/creel_logo_yellow.svg';
import { LogOut } from 'lucide-react';

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const handleSignIn = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    signInWithEmailAndPassword(auth, email, password);

    e.target.email.value = '';
    e.target.password.value = '';
  }
  const handleSignUp = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const name = e.target.name.value;
    const dealership = e.target.dealership.value;
    const phone = e.target.phone.value;

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const docRef = doc(db, 'dealers', user.uid);
        setDoc(docRef, { name: name, dealership: dealership, email: email, phone: phone });
        signInWithEmailAndPassword(auth, email, password);
      })
      .catch((err) => {
        console.log(err.message);
      });

    e.target.email.value = '';
    e.target.password.value = '';
    setShowSignUpForm(false);
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsSignedIn(true);
        const docRef = doc(db, 'dealers', user.uid);
        getDoc(docRef)
          .then(docSnap => {
            setUserDetails(docSnap.data());
          })
          .catch(err => {
            console.log(err.message);
          });
      } else {
        setIsSignedIn(false);
      }
    });
  }, []);

  return (
    <div >
      {isSignedIn
        ?
        <div className='app-wrapper'>
          <div className='nav-bar'>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <img src={logo} width={40} />
              <h2>Creel</h2>
            </div>
            <div className='profile'>
              {userDetails.name && <div className='avatar'>{userDetails.name[0]}</div>}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className='label' style={{ margin: 0 }}>{userDetails.dealership}</p>
                <h3>{userDetails.name}</h3>
              </div>
              <button className='btn-signout' onClick={() => signOut(auth)}>
                <LogOut size={20} />
              </button>
            </div>
          </div>
          <Dashboard />
        </div>
        :
        <div className='sign-in-screen-wrapper'>
          {
            showSignUpForm
              ?
              <div className='sign-in-card'>
                <div className='sign-in-card-top'>
                  <img src={logo} width={40} />
                  <h2>Welcome to Creel</h2>
                  <p style={{ marginTop: -8 }}>Your trusted sales companion</p>
                </div>
                <form onSubmit={e => handleSignUp(e)}>
                  <input name='name' placeholder='Full Name' autoComplete='off' />
                  <input name='dealership' placeholder='Dealership Name' autoComplete='off' />
                  <input name='phone' placeholder='(123) 456 7890' autoComplete='off' />
                  <input name='email' placeholder='Email' autoComplete='off' />
                  <input name='password' placeholder='Password' type='password' />
                  <button>Sign Up</button>
                </form>
                <div style={{ display: 'flex', gap: 8 }}>
                  <p>Already have an account?</p>
                  <p className='link-button' onClick={() => setShowSignUpForm(false)}>Sign In</p>
                </div>
              </div >
              :
              <div className='sign-in-card'>
                <div className='sign-in-card-top'>
                  <img src={logo} width={40} />
                  <h2>Welcome to Creel</h2>
                  <p style={{ marginTop: -8 }}>Your trusted sales companion</p>
                </div>
                <form onSubmit={e => handleSignIn(e)}>
                  <input name='email' placeholder='Email' autoComplete='off' />
                  <input name='password' placeholder='Password' type='password' />
                  <button>Sign In</button>
                </form>
                <div style={{ display: 'flex', gap: 8 }}>
                  <p>Don't have an account yet?</p>
                  <p className='link-button' onClick={() => setShowSignUpForm(true)}>Sign Up</p>
                </div>
              </div >
          }</div>

      }

    </div >
  );
}

export default App;