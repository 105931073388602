import { useState, useEffect, useRef } from 'react';
import { db } from './firebase';
import { doc, collection, getDoc, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { Car, Settings, EyeIcon, EyeOff, Inbox, MousePointerSquare } from 'lucide-react';

import illustration from './assets/illustrations.svg';

function Dashboard() {
  const chatTabRef = useRef();
  const hookTabRef = useRef();
  const settingsTabRef = useRef();

  const [UID, setUID] = useState();
  const [settings, setSettings] = useState({});
  const [listOfChats, setListOfChats] = useState([]);
  const [chat, setChat] = useState();
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [booking, setBooking] = useState();
  const [imageUrl, setImageUrl] = useState('');
  const [showLostLeads, setShowLostLeads] = useState(true);
  const [activeTab, setActiveTab] = useState('chatTab');
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [showCanceledMessage, setShowCanceledMessage] = useState(false);

  const fetchChats = async (UID) => {
    const colRef = collection(db, 'dealers', UID, 'chats');
    const colSnap = await getDocs(colRef);
    const loc = [...listOfChats];
    colSnap.forEach((doc) => {
      const docData = doc.data();
      const l = docData.chat.length;
      const lastMessage = docData.chat[l - 1].content;
      if (l > 1) {
        if (docData.appointmentDetails) {
          loc.push({ docId: doc.id, lastMessage: lastMessage, carOfInterest: docData.carOfInterest, prospectName: docData.appointmentDetails.prospectName });
        } else {
          loc.push({ docId: doc.id, lastMessage: lastMessage, carOfInterest: docData.carOfInterest, prospectName: 'Someone' });
        }
      }

    });
    loc.sort((a, b) => b.docId - a.docId);
    setListOfChats(loc);
  }

  const fetchSettings = async (UID) => {
    const docRef = doc(db, 'dealers', UID);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    setSettings(data);
  }

  const handleLoadChat = async (e) => {
    const docId = e.target.attributes.docid.value;
    const docRef = doc(db, 'dealers', UID, 'chats', docId);
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();
    setChat(docData.chat);
    if (docData.appointmentDetails) {
      setAppointmentDetails(docData.appointmentDetails);
      const date = new Date(docData.appointmentDetails.appointmentDate.seconds * 1000);
      setBooking(date.toLocaleString('en-US', { weekday: 'long', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }));
      setImageUrl(docData.carImage);
    } else {
      setAppointmentDetails(null);
    }
  }

  const getMessageTimeStamp = (docId) => {
    const milliseconds = parseInt(docId);
    const date = new Date(milliseconds);
    const time = date.toLocaleString('en-US', { weekday: 'long', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' })
    return (time);
  }

  const handleTabClick = (e) => {
    const clickedTab = e.target.id;
    setActiveTab(clickedTab);
  }

  const handleInputChange = (e) => {
    const tmp = { ...settings };
    const inputName = e.target.name;
    if (e.target.type === 'checkbox') {
      tmp[inputName] = e.target.checked;
    } else {
      tmp[inputName] = e.target.value;
    }

    setSettings(tmp);
  }

  const handleSaveSettings = (e) => {
    e.preventDefault();
    const docRef = doc(db, 'dealers', UID);
    updateDoc(docRef, settings);
    setShowSavedMessage(true);
    setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000);
  }

  const handleCancelSettings = (e) => {
    e.preventDefault();
    fetchSettings(UID);
    setShowCanceledMessage(true);
    setTimeout(() => {
      setShowCanceledMessage(false);
    }, 3000);
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUID(user.uid);
        fetchChats(user.uid);
        fetchSettings(user.uid);
      } else {
        // logged out
      }
    });
  }, [showCanceledMessage, showSavedMessage]);

  useEffect(() => {
    if (activeTab === 'chatTab') {
      chatTabRef.current.className = 'tab t-active';
      // hookTabRef.current.className = 'tab';
      settingsTabRef.current.className = 'tab';
    } else if (activeTab === 'hookTab') {
      chatTabRef.current.className = 'tab';
      // hookTabRef.current.className = 'tab t-active';
      settingsTabRef.current.className = 'tab';
    } else if (activeTab === 'settingsTab') {
      chatTabRef.current.className = 'tab';
      // hookTabRef.current.className = 'tab';
      settingsTabRef.current.className = 'tab t-active';
    }
  }, [activeTab]);

  return (
    <div className='dashboard-container'>
      <div className='side-bar'>
        <div ref={chatTabRef} id='chatTab' className='tab' onClick={(e) => handleTabClick(e)}><Inbox size={24} />Chat History</div>
        {/* <div ref={hookTabRef} id='hookTab' className='tab' onClick={(e) => handleTabClick(e)}><MousePointerSquare size={24} />Lead Hook</div> */}
        <div ref={settingsTabRef} id='settingsTab' className='tab' onClick={(e) => handleTabClick(e)}><Settings size={24} />Settings</div>
      </div>
      {activeTab === 'chatTab' &&
        <>
          <div className='chat-history'>
            <div className='header'>
              <h2>Chat History</h2>
              <div className='filter' onClick={() => setShowLostLeads(!showLostLeads)}>
                {showLostLeads ?
                  <>
                    <EyeOff size={16} />
                    <p>Hide lost leads</p>
                  </>
                  :
                  <>
                    <EyeIcon size={16} />
                    <p>Show lost leads</p>
                  </>
                }
              </div>
            </div>
            <ul>
              {listOfChats.map((chat) => {
                return (
                  <li
                    className={!showLostLeads ? (chat.prospectName !== 'Someone' ? 'chat' : 'chat hidden') : 'chat'}
                    key={chat.docId}
                    docid={chat.docId}
                    onClick={e => handleLoadChat(e)}
                  >
                    <h3 style={{ pointerEvents: 'none', marginBottom: 8 }}>
                      {chat.carOfInterest}
                    </h3>
                    {chat.lastMessage}
                    {chat.prospectName !== 'Someone' ?
                      <p
                        style={{ fontSize: 12, color: 'var(--neutral-medium)', marginTop: '4px', pointerEvents: 'none' }}
                      >
                        {`${getMessageTimeStamp(chat.docId)} • `}
                        <span style={{ color: 'var(--secondary)' }}>{chat.prospectName}</span>
                      </p>
                      :
                      <p
                        style={{ fontSize: 12, color: 'var(--neutral-medium)', marginTop: '4px', pointerEvents: 'none' }}
                      >
                        {`${getMessageTimeStamp(chat.docId)}`}
                      </p>
                    }
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='chat-preview-container'>
            {
              appointmentDetails &&
              <div className='chat-header'>
                {imageUrl ? <img src={imageUrl} width={200} style={{ aspectRatio: 1.6, objectFit: 'cover' }} /> : <div style={{ width: 200, aspectRatio: 1.6, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#e1e0da' }}><Car size={80} color='#c9c8c0' /></div>}
                <div className='booking-card'>
                  <div>
                    <p className='label'>Prospect Name</p>
                    <p>{appointmentDetails.prospectName}</p>
                  </div>
                  <div>
                    <p className='label'>Phone Number</p>
                    <p>{appointmentDetails.prospectPhone}</p>
                  </div>
                  <div>
                    <p className='label'>Booking</p>
                    <p>{booking}</p>
                  </div>
                </div>
              </div>
            }
            <div className='chat-container'>
              {chat ?
                <ul>
                  <div style={{ flex: 1 }}></div>
                  {chat.map((message, index) => {
                    if (index > 0)
                      return (
                        <li
                          className={`message-container m-c-${message.role}`}
                          key={index}
                        >
                          <div className={`message-bubble m-b-${message.role}`}>
                            <p>{message.content}</p>
                          </div>
                        </li>
                      );
                  })}
                </ul>
                :
                <div className='chat-preview-empty'>
                  <img src={illustration} width={500} style={{ marginTop: -100, marginBottom: -70 }} />
                  <p style={{ width: 320, textAlign: 'center', marginTop: 8, color: 'var(--secondary)' }}>With Creel, reel in leads like there's always more fish in the sea!</p>
                </div>
              }
            </div>
          </div >
        </>
      }
      {activeTab === 'settingsTab' &&
        <div className='settings-container'>
          <div className='header'>
            <h2>Settings</h2>
          </div>
          <form onSubmit={(e) => handleSaveSettings(e)} onReset={(e) => handleCancelSettings(e)} className='settings-form'>
            <div className='form-section'>
              <h3>Basic Information</h3>
              <label>Your Name</label>
              <input onChange={e => handleInputChange(e)} value={settings.name} name='name' placeholder='Owner/Manager full name' />
              <label>Dealership Name</label>
              <input onChange={e => handleInputChange(e)} value={settings.dealership} name='dealership' placeholder='Your dealership name' />
              <label>Contact Email</label>
              <input onChange={e => handleInputChange(e)} value={settings.email} name='email' placeholder='The best eamail to reach you' />
              <label>Phone Number</label>
              <input onChange={e => handleInputChange(e)} value={settings.phone} name='phone' placeholder='(123) 456-7890' />
              <label>Address</label>
              <input onChange={e => handleInputChange(e)} value={settings.address} name='address' placeholder='1, Pine Wood St, San Francisco, CA' />
              <label>Operating Hours</label>
              <input onChange={e => handleInputChange(e)} value={settings.hours} name='hours' placeholder='Mon-Fri 9-5' />
            </div>
            <div className='form-section'>
              <h3>Dealership Policies</h3>
              <div className='checkboxContainer'>
                <label>Financing</label>
                <input onChange={e => handleInputChange(e)} value={settings.financing} name='financing' className='checkbox' type='checkbox' />
              </div>
              <div className='checkboxContainer'>
                <label>Warranties</label>
                <input onChange={e => handleInputChange(e)} checked={settings.warranties} name='warranties' className='checkbox' type='checkbox' />
              </div>
              <label>Offers</label>
              <input onChange={e => handleInputChange(e)} checked={settings.offers} name='offers' placeholder='Whar offers are you currently running?' />
            </div>
            <div className='form-section'>
              <h3>Other</h3>
              <label>Additional Information</label>
              <textarea onChange={e => handleInputChange(e)} value={settings.additionalInformation} name='additionalInformation' placeholder='Include details that could help the AI respond better' />
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
              <button type='reset' style={{ width: 100 }}>Cancel</button>
              <button type='submit' style={{ width: 100 }}> Save</button>
            </div>
            {showSavedMessage && <p className='txt-changes saved'>Changes saved!</p>}
            {showCanceledMessage && <p className='txt-changes canceled'>Changes canceled</p>}
          </form>
        </div >
      }
    </div >
  );
}

export default Dashboard;